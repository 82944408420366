import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import AssetManagement from 'xdock/AssetManagement';

export default function AssetManagementContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <div class="bg-background h-full">
          <AssetManagement />
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
