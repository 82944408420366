import React from 'react';
import ScannerIcon from '@material-ui/icons/PhoneAndroid';
import Text from 'components/Text';
import Link from 'components/Link';
import { useQuery } from 'react-query';
import fetch from 'utils/fetch';
import { useGState } from 'state/store';

export default function AssetManagement() {
  const xdock = useGState((s) => s.branch);
  const { data: assets = { scanners: {}, pallets: {} } } = useQuery(
    xdock?.id && ['assets', xdock.id],
    () => fetch(`/falcon/xdock/assets/panel/assetInfo?xdockId=${xdock.id}`)
  );

  const data = [
    {
      heading: 'Scanners',
      total: assets.scanners.active + assets.scanners.inactive,
      active: assets.scanners.active,
      busy: assets.scanners.busy,
      inactive: assets.scanners.inactive,
      link: '/xd/scanners',
    },
    {
      heading: 'Pallets',
      total: assets.pallets.active + assets.pallets.inactive,
      active: assets.pallets.active,
      busy: assets.pallets.full,
      inactive: assets.pallets.inactive,
      link: '/xd/pallets',
    },
    {
      heading: 'Crates',
      na: true,
      link: '/xd/assetManagement',
    },
  ];

  return (
    <div class="p-6">
      <Text class="text-3xl">Asset Management</Text>
      <div class="grid md:grid-cols-3  gap-4 pt-6">
        {data.map((details, index) => (
          <GistCart data={details} key={index}></GistCart>
        ))}
      </div>
    </div>
  );
}

function GistCart({ data: { heading, total, active, busy, inactive, na, link } }) {
  return (
    <Link to={link}>
      <div class="bg-white rounded-lg p-5 shadow h-fi">
        <div class="flex content-center">
          <div class="self-center pr-2">
            <ScannerIcon />
          </div>
          <div>
            <Text class="text-sm font-semibold text-primary">{heading}</Text>
            {na ? (
              <Text class="text-inactive text-tiny ">Not Available</Text>
            ) : (
              <Text class="text-tiny  text-primary">{`Total: ${total}`}</Text>
            )}
          </div>
        </div>
        {!na && (
          <div class="grid grid-cols-3 gap-2 mt-3">
            <div class="bg-success-light rounded py-2 text-center text-success font-bold">
              <Text>{active}</Text>
              <Text class="text-tiny">Active</Text>
            </div>
            <div class="bg-warn-light rounded py-2 text-center text-warn font-bold">
              <Text>{busy}</Text>
              <Text class="text-tiny">Busy</Text>
            </div>
            <div class="bg-inactive-light rounded py-2 text-center text-inactive font-bold">
              <Text>{inactive}</Text>
              <Text class="text-tiny">Inactive</Text>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}
